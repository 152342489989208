<template>
    <div>
        <h1>TBD</h1>
    </div>
</template>

<script>
    export default {
        name: 'TheUserTickets',
    };
</script>
